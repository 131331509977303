.App-main {
  background-color: #282c34;
}

div.cardWrapper {
  border-radius: 25px;
}

a {
  text-decoration: none;
  color: black;
  display: flex;
}

a.homepage {
  color: white;
  width: 100%;
}

/* notifications */
div {
  &.notistack-MuiContent {
    color: black;
    border-radius: 14px;
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
    padding-right: 20px;
    svg {
      padding-right: 10px;
    }
  };
  &.notistack-MuiContent-success {
    background-color: rgb(237, 247, 237);
    svg {
      fill: #2e7d32 !important;
    }
  };
  &.notistack-MuiContent-error {
    background-color: rgb(253, 237, 237);
    svg {
      fill: #d32f2f !important;
    }
  };
  &.notistack-MuiContent-warning {
    background-color: rgb(255, 244, 229);
    svg {
      fill: #ed6c02 !important;
    }
  };
  &.notistack-MuiContent-info {
    background-color: rgb(229, 246, 253);
    svg {
      fill: #0288d1 !important;
    }
  }
}

/* date picker */
div {
  &.react-datepicker {
    font-family: "Poppins", "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 2;
    border: unset;
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.3);
    background-color: #f3f3f3;
  };
  &.react-datepicker__navigation-icon {
    top: unset;
  };
  &.react-datepicker__header {
    background-color: #eeeeee;
    border-bottom: unset;
    box-shadow: 0px 5px 10px -3px rgba(0,0,0,0.2);
  };
  &.react-datepicker-wrapper {
    width: 100%;
  };
  &.react-datepicker__input-container {
    display: flex;
  };
}

button.react-datepicker__close-icon{
  height: min-content;
  padding-right: 10px;
  top: 50%;
  transform: translateY(-50%);
  &::after {
    background-color: #FF791E;
  }
  &:hover::after {
    transition: 0.2s;
    background-color: #D65701;
  }
}

/* Pagination */
ul.pagination {
  display: flex;
  list-style: none;
  padding-inline-start: unset;
}

a.pagination {
  user-select: none;
  color: white;
  justify-content: center;
  align-items: center;
  padding-top: 1px;
  margin: 0px 0.625rem;
  border-radius: 100%;
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
  transition: 0.3s;
  &:hover {
    transition: 0.3s;
    background-color: rgba(255,255,255,0.1)
  }
  &.active {
    background-color: rgba(255,255,255,0.1);
    font-weight: bold;
  }
  &.disabled {
    color: lightgray;
    cursor: default;
    &:hover {
      background-color: unset;
    }
  }
}

/* Responsiveness */
@media only screen and (min-width: 600px) { /* sm */
  ul.pagination {
    font-size: 9px;
  }
}

@media only screen and (min-width: 900px) { /* md */
  ul.pagination {
    font-size: 12px;
  }
}

@media only screen and (min-width: 1400px) { /* lg */
  ul.pagination {
    font-size: 17px;
  }
}

@media only screen and (min-width: 2000px) { /* xl */
  ul.pagination {
    font-size: 13px;
  }
}

@media only screen and (min-width: 2100px) { /* xxl */
  ul.pagination {
    font-size: 15px;
  }
}

@media only screen and (max-width: 1400px) { /* lg */
  .App-main {
    padding-top: 30px;
  }
}


