@font-face {
    font-family: Poppins;
    src: url("../public/fonts/Poppins-Thin.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: Poppins;
    src: url("../public/fonts/Poppins-ThinItalic.ttf") format("truetype");
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: Poppins;
    src: url("../public/fonts/Poppins-ExtraLight.ttf") format("truetype");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: Poppins;
    src: url("../public/fonts/Poppins-ExtraLightItalic.ttf") format("truetype");
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: Poppins;
    src: url("../public/fonts/Poppins-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: Poppins;
    src: url("../public/fonts/Poppins-LightItalic.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: Poppins;
    src: url("../public/fonts/Poppins-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: Poppins;
    src: url("../public/fonts/Poppins-Italic.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: Poppins;
    src: url("../public/fonts/Poppins-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: Poppins;
    src: url("../public/fonts/Poppins-MediumItalic.ttf") format("truetype");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: Poppins;
    src: url("../public/fonts/Poppins-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: Poppins;
    src: url("../public/fonts/Poppins-SemiBoldItalic.ttf") format("truetype");
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: Poppins;
    src: url("../public/fonts/Poppins-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: Poppins;
    src: url("../public/fonts/Poppins-BoldItalic.ttf") format("truetype");
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: Poppins;
    src: url("../public/fonts/Poppins-ExtraBold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: Poppins;
    src: url("../public/fonts/Poppins-ExtraBoldItalic.ttf") format("truetype");
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: Poppins;
    src: url("../public/fonts/Poppins-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: Poppins;
    src: url("../public/fonts/Poppins-BlackItalic.ttf") format("truetype");
    font-weight: 900;
    font-style: italic;
}